import './App.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Twitter, Envelope, Discord, CollectionPlayFill } from 'react-bootstrap-icons';

function App() {
  return (
    <div className="App">
      <div className="App-content">
        <Container className="mt-5">
        {/* <Row>
          <Col className="pt-4">
            <img src="assets/logo-font.png" alt="logo" width="75%" />
          </Col>
          <Col className="text-end">
            <img src="assets/character.png" alt="logo" width="75%" />
          </Col>
        </Row> */}

        <Row className="justify-content-md-center mb-5">
          <Col xs={12} md={8}>
            <Image src="assets/logo-blend.png" fluid />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4}>
            <Image src="assets/bb-j.png" fluid />
          </Col>
          <Col xs={12} md={4}>
            <Image src="assets/bb-flames.png" fluid />
          </Col>
          <Col xs={12} md={4}>
            <Image src="assets/bb-foam.png" fluid />
          </Col>
        </Row>
        {/* <div className="bb-container">
          <div className="bb-1"><img src="assets/bb-j.png" alt="logo" width="100%" /></div>
          <div className="bb-2"><img src="assets/bb-flames.png" alt="logo" width="100%" /></div>
          <div className="bb-3"><img src="assets/bb-foam.png" alt="logo" width="100%" /></div>
        </div> */}
        
        {/* <Row>
          <Col>
            <img src="assets/character.png" alt="logo" width="100%" />
          </Col>
        </Row> */}
        {/* <h1 className="logo">₿A$$ M◎NΞY</h1> */}
        {/* <h4 className="mb-5"><em>A revolutionary audio NFT project to connect musicians and fans.</em></h4> */}
        
        <Row>
          <Col>
          <h3 className="header mt-5">Vision</h3>
        <p>We want to transform the way music is created, distributed and monetized. 
          Most audio NFT projects, to-date, have either focused on royalty sharing or exclusive merchandising and ticket giveaways. 
          Our goal is to give the NFT holders a unique piece of music that they own, directly from the artist.</p>
          <p>Similar to the way a lot of projects generate profile pics (apes, monkeys, cats, etc) the Bass Money team has developed an algorithm to generate instrumentals using a random combination of different layers and sequences exported from a musician's DAW.
        We think this opens up huge opportunities for artists to directly connect with their fans. A fan will now be able to own a piece of music created by their favorite artist that was literally made just for them!
        </p>
          </Col>
        </Row>

        <Row>
          <Col>

        <h3 className="mt-5 header">Genesis Drop</h3>
        <p>Each NFT minted will include a custom, sequenced instrumental along with a generative Bass Money beatbox character.</p>
        
        <p>
        Our genesis drop will feature a combination of uniquely-generated beats from a handful of different artists. 
        Our hope is that the momentum and revenue from this drop will allow each of the collaborating artists to launch an exclusive collection <em>(ie. Timbaland x Bass Money drop)</em>.
        </p>
          </Col>
        </Row>

        <Row>
          <Col>
          <h3 className="mt-5 header">Roadmap</h3>
        <p>We have even more surprises for the roadmap and will be sharing details soon!</p>
          </Col>
        </Row>
 
        </Container>

        <Container>
          <Row className="mt-5">
            <h3 className="header">Audio</h3>
              <Col>
              <h5>Collage sample (for drop promos)</h5>
              <Row>
              <Col>
                  <audio controls>
                    <source src="assets/new-3.mp3" type="audio/mpeg"></source>
                    Your browser does not support the audio element.
                  </audio>
                </Col>
                {/* <Col>
                  <audio controls>
                    <source src="assets/demo.mp3" type="audio/mpeg"></source>
                    Your browser does not support the audio element.
                  </audio>
                </Col> */}
              </Row>
              
              </Col>
          </Row>

          <Row className="mt-5">
            <Col>
            <h5>NFT samples</h5>
              <Row>
                <Col>
                  <audio controls>
                    <source src="assets/nft2-10.mp3" type="audio/mpeg"></source>
                    Your browser does not support the audio element.
                  </audio>
                </Col>

                <Col>
                  <audio controls>
                    <source src="assets/nft2-2.mp3" type="audio/mpeg"></source>
                    Your browser does not support the audio element.
                  </audio>
                </Col>

                <Col>
                  <audio controls>
                    <source src="assets/nft2-3.mp3" type="audio/mpeg"></source>
                    Your browser does not support the audio element.
                  </audio>
                </Col>
              </Row>

              <Row>
                <Col>
                  <audio controls>
                    <source src="assets/notech-3.mp3" type="audio/mpeg"></source>
                    Your browser does not support the audio element.
                  </audio>
                </Col>

                <Col>
                  <audio controls>
                    <source src="assets/notech-8.mp3" type="audio/mpeg"></source>
                    Your browser does not support the audio element.
                  </audio>
                </Col>

                <Col>
                  <audio controls>
                    <source src="assets/notech-20.mp3" type="audio/mpeg"></source>
                    Your browser does not support the audio element.
                  </audio>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                <p>* Samples are for demonstration purposes only. They will not be in the drop.</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Container className="my-5">
          <Row>
            <Col className="d-flex justify-content-center">
              <a className="App-link px-5" target="_blank" href="https://twitter.com/BassMoneyNFT">
                <Twitter size={64} />
              </a>
              <a className="App-link px-5" target="_blank" href="https://discord.gg/am9wdkuaG4">
                <Discord size={64} />
              </a>
              <a className="App-link px-5" href="mailto:bassmoney@protonmail.com">
                <Envelope size={64} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
